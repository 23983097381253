import { FC } from 'react'
import Head from 'next/head'
import ErrorPage from '../../components/ErrorPage'
import { useTranslation } from 'src/@legacy/@core/hooks/UserTranslation'
import withAppProvider from 'src/@legacy/layout/hoc/withAppProvider'

const Page404: FC = () => {
  const { translate, currentLanguage } = useTranslation()
  return (
    <>
      <Head>
        <meta name="robots" content="noindex, nofollow" />
        <title>{translate('meta:page_does_not_exist')}</title>
      </Head>
      <ErrorPage
        urlImg="/img/404.png"
        title={currentLanguage === 'en' ? 'That page does not exist...' : 'Trang web không tồn tại'}
        content={
          currentLanguage === 'en'
            ? `We couldn’t find what you’re looking for. Let’s find a better place for you to go.`
            : 'Rất tiếc, địa chỉ URL bạn yêu cầu không được tìm thấy.'
        }
      />
    </>
  )
}

export default withAppProvider(Page404)

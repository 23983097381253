import React, { FC } from 'react'
import useAppRouter from '../../hooks/useAppRouter'
import Button from '../base/buttons/Button'
import Col from '../base/Grid/Col'
import Row from '../base/Grid/Row'
import Image from 'next/image'
// import { useTranslation } from '../../hooks/useTranslation'
import { LINK_URL_CHAT_WITH_SUPPORT, LINK_URL_ROOT } from '../../utillities/LinkURL'
import style from './error.module.scss'
import { useTranslation } from 'src/@legacy/@core/hooks/UserTranslation'

const ErrorPage: FC<{ urlImg: string; title: string; content: string }> = ({ urlImg, title, content }) => {
  const { translate } = useTranslation()
  const router = useAppRouter()
  const goHomePage = () => {
    router.push(LINK_URL_ROOT)
  }
  return (
    <div className={style.root}>
      <div className="wrapper-container">
        <div className="err-page">
          <Row justify="center" align="middle" style={{ display: 'flex', alignItems: 'center' }} className="h-100">
            <Col xs={24} md={14}>
              <div className="image">
                <Image src={urlImg} alt="background" width={444} height={270} />
              </div>
              <p className="title">{title}</p>
              <p className="sub-title">{content}</p>

              <div className="d-flex align-items-center justify-content-center">
                {/* <div className="d-flex navigate-button-group"> */}
                <div className="mr-4">
                  <Button
                    className="ant-btn primary"
                    onClick={() => {
                      router.push(LINK_URL_CHAT_WITH_SUPPORT)
                    }}>
                    {translate('button:error_page_contact_us')}
                  </Button>
                </div>

                <Button className="ant-btn" type="primary" onClick={goHomePage}>
                  {translate('button:go_to_home_page')}
                </Button>
                {/* </div> */}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}
//@ts-ignore
export default ErrorPage
